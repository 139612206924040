// pages/videoplayer.tsx

import { useEffect } from 'react';

const VideoPlayerPage = () => {
  useEffect(() => {
    // Dynamically load PlayerJS when the component mounts
    const loadPlayerJS = () => {
      const script = document.createElement('script');
      script.src = '//assets.mediadelivery.net/playerjs/player-0.1.0.min.js';
      script.async = true;
      script.onload = () => initializePlayer();
      document.body.appendChild(script);
    };

    const initializePlayer = () => {
      const player = new (window).playerjs.Player(
        document.getElementById('bunny-stream-embed')
      );

      // Event handlers
      player.on('ready', () => console.log('Player is ready'));
      player.on('play', () => console.log('Player started playing'));
      player.on('pause', () => console.log('Player is paused'));
      player.on('timeupdate', (data) => console.log(`Time update: ${data}`));

      // Add event listeners for buttons
      document.getElementById('play')?.addEventListener('click', () => {
        player.play();
      });
      document.getElementById('pause')?.addEventListener('click', () => {
        player.pause();
      });
      document.getElementById('seek')?.addEventListener('click', () => {
        player.getCurrentTime((currentTime) => {
          player.setCurrentTime(currentTime + 10);
        });
      });
    };

    // Load the PlayerJS library
    loadPlayerJS();
  }, []);

  return (
    <div>
      {/* Bunny Stream iframe for video player */}
      <iframe
        id="bunny-stream-embed"
        src="https://iframe.mediadelivery.net/embed/110285/44a321b4-37df-4ecd-8a80-dbebab4ece3a"
        width="720"
        height="400"
        frameBorder="0"
        allow="autoplay"
      ></iframe>

      {/* Buttons for controlling the video */}
      <div>
        <button id="play">Play</button>
        <button id="pause">Pause</button>
        <button id="seek">Seek to 10s</button>
      </div>

      <style jsx>{`
        body {
          font-family: Arial, sans-serif;
          background-color: #f0f0f0;
          margin: 0;
          padding: 0;
        }
        iframe {
          display: block;
          margin: 20px auto;
        }
        div {
          text-align: center;
          margin-top: 20px;
        }
        button {
          padding: 10px 20px;
          font-size: 16px;
          background-color: #0077cc;
          color: #fff;
          border: none;
          cursor: pointer;
          margin: 5px;
        }
      `}</style>
    </div>
  );
};

export default VideoPlayerPage;
