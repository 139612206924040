import React, { useState, useEffect } from "react";
// import Webcam from 'react-webcam'
import Axios from "axios";
import Image from "../../assets/Background.png";
import WebcamCapture from "../Pages/Camera";
import { useLottie } from "lottie-react";
import coins from "../Lotties/coins.json";
import clock from "../Lotties/clock3.json";
import ambstats from "../Lotties/amb_stats.json";
import video from "../Lotties/video2.json";
import referral from "../Lotties/referral.json";
import Navbar from "./navbar";
import Footer from "./footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layer from "../../../src/assets/Image 27.png";
import QRCode from "react-qr-code";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBFile,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardLink,
  MDBListGroup,
  MDBListGroupItem,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
  MDBFooter,
  MDBDropdownDivider,
  MDBCardHeader,
  MDBCardFooter,
} from "mdb-react-ui-kit";
function EditableProfile(props) {
  const [data, setData] = useState([]);
  const [quali, setQuali] = useState();
  const userId = sessionStorage.getItem("userid");
  const [image, setImage] = useState();
  const [coin, setcoins] = useState("");
  const [since, setsince] = useState("");
  const [userid, setuserid] = useState("");
  const [videos, setvideo] = useState("");
  const [Email, setEmail] = useState("");
  const [Gender, setGender] = useState("");
  const [Toggle, setToggle] = useState("Male");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [frndmobile, setMobileNumber] = useState("");
  const [myNumber, setMyNumber] = useState("");
  const [frndname, setName] = useState("");
  const [City, setCity] = useState("");
  const [Statee, setStatee] = useState("");
  const [Password, setPassword] = useState("");
  const [basicActive, setBasicActive] = useState("tab1");
  const [basicModal, setBasicModal] = useState(false);
  const [Modal, setModal] = useState(false);
  const [SQRCode, setQRCode] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const Show = () => setModal(!Modal);
  const ShowQRCode = () => setQRCode(!SQRCode);
  const Mobile = sessionStorage.getItem("usermobile");
  const [Photo, SetPhoto] = useState(false);
  const [CurLevel, setCurLevel] = useState("");
  const [NextLevel, setNextLevel] = useState("");
  const [RefCode, setRefCode] = useState("");
  const [RequiredPoints, setRequiredPoints] = useState("");
  const formval = { fontWeight: "bold", color: "red", fontSize: "12px" };
  const [eMobile, seteMobile] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ErrMobile, setErrMobile] = useState("");
  const [ErrOldPassword, setErrOldPassword] = useState("");
  const [ErrNewPassword, setErrNewPassword] = useState("");
  const [AmbStats, setAmbStats] = useState([]);
  sessionStorage.setItem("view", videos);
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  // console.log(firstname);
  sessionStorage.setItem("username", firstname);
  // console.log(Email);
  const [Grade, setGrade] = useState("");
  sessionStorage.setItem("grade", Grade);
  sessionStorage.setItem("image", image);
  const Level = sessionStorage.getItem("level");

  // const Photo = sessionStorage.getItem("Photo");
  const bgimage = {
    backgroundImage: `url(${Image})`,
    // backgroundSize: 'cover',
    height: "100vh",
  };
  // console.log(image);
  // console.log(Email);
  const Qualification = [
    {
      Value: "M",
      Text: "Male",
    },
    {
      Value: "F",
      Text: "Female",
    },
    {
      Value: "P",
      Text: "Prefer Not to Say",
    },
  ];
  const state = [
    {
      Value: "Andhra Pradesh",
      Text: "Andhra Pradesh",
    },
    {
      Value: "Arunachal Pradesh",
      Text: "Arunachal Pradesh",
    },
    {
      Value: "Assam",
      Text: "Assam",
    },
    {
      Value: "Bihar",
      Text: "Bihar",
    },

    {
      Value: "Chhattisgarh",
      Text: "Chhattisgarh",
    },
    {
      Value: "Goa",
      Text: "Goa",
    },

    {
      Value: "Gujarat",
      Text: "Gujarat",
    },

    {
      Value: "Haryana",
      Text: "Haryana",
    },

    {
      Value: "Himachal Pradesh",
      Text: "Himachal Pradesh",
    },
    {
      Value: "Jharkhand",
      Text: "Jharkhand",
    },
    {
      Value: "Karnataka",
      Text: "Karnataka",
    },
    {
      Value: "Kerala",
      Text: "Kerala",
    },

    {
      Value: "Madhya Pradesh",
      Text: "Madhya Pradesh",
    },
    {
      Value: "Maharashtra",
      Text: "Maharashtra",
    },
    {
      Value: "Manipur",
      Text: "Manipur",
    },
    {
      Value: "Meghalaya",
      Text: "Meghalaya",
    },
    {
      Value: "Mizoram",
      Text: "Mizoram",
    },
    {
      Value: "Nagaland",
      Text: "Nagaland",
    },
    {
      Value: "Odisha",
      Text: "Odisha",
    },
    {
      Value: "Punjab",
      Text: "Punjab",
    },
    {
      Value: "Rajasthan",
      Text: "Rajasthan",
    },
    {
      Value: "Sikkim",
      Text: "Sikkim",
    },
    {
      Value: "Tamil Nadu",
      Text: "Tamil Nadu",
    },
    {
      Value: "Telangana",
      Text: "Telangana",
    },
    {
      Value: "Tripura",
      Text: "Tripura",
    },
    {
      Value: "Uttar Pradesh",
      Text: "Uttar Pradesh",
    },
    {
      Value: "Uttarakhand",
      Text: "Uttarakhand",
    },
    {
      Value: "West Bengal",
      Text: "West Bengal",
    },
    {
      Value: "Andaman and Nicobar Islands (UT)",
      Text: "Andaman and Nicobar Islands (UT)",
    },
    {
      Value: "Chandigarh (UT)",
      Text: "Chandigarh (UT)",
    },
    {
      Value: "Dadra & Nagar Haveli and Daman & Diu (UT)",
      Text: "Dadra & Nagar Haveli and Daman & Diu (UT)",
    },
    {
      Value: "Delhi (UT)",
      Text: "Delhi (UT)",
    },
    {
      Value: "Jammu and Kashmir (UT)",
      Text: "Jammu and Kashmir (UT)",
    },
    {
      Value: "Lakshadweep (UT)",
      Text: "Lakshadweep (UT)",
    },
    {
      Value: "Puducherry (UT)",
      Text: "Puducherry (UT)",
    },
    {
      Value: "Ladakh (UT)",
      Text: "Ladakh (UT)",
    },
  ];

  useEffect(() => {
    const Mobile = sessionStorage.getItem("usermobile");
    const userId = sessionStorage.getItem("userid");
    if (
      sessionStorage.getItem("userid") === null ||
      sessionStorage.getItem("userid") === undefined
    ) {
      window.location.href = "/";
    }
    // console.log(Mobile);
    // getJSONFile(`../Lotties/coins.json`)
    Axios.get(`/user/StudentRecords/${userId}`).then((response) => {
      setData(response.data.recordset);
      // console.log(response.data.recordset, "<<<<userinfo>>>>>.");
      if (response.data.recordset) {
        setuserid(response.data.recordset[0].USER_ID || "");
        // console.log("1");
        setfirstname(response.data.recordset[0].FIRST_NAME || "");
        // console.log("2");
        setlastname(response.data.recordset[0].LAST_NAME || "");
        // console.log("3");
        setEmail(response.data.recordset[0].EMAIL || "");
        // console.log("4");
        setMyNumber(response.data.recordset[0].MOBILE_NUMBER || "");
        // console.log("5");
        setGender(response.data.recordset[0].GENDER || "");
        setGrade(
          `${response.data.recordset[0].DEGREE || "N/A"}, ${
            response.data.recordset[0].SCHOOL_NAME || "N/A"
          }`
        );
        setStatee(response.data.recordset[0].STATE || "");
        setCity(response.data.recordset[0].CITY || "");
        setImage(response.data.recordset[0].USER_PHOTO || "");
        setcoins(response.data.recordset[0].COINS || "");
        setsince(response.data.recordset[0].MEMBER_SINCE || "");
        setvideo(response.data.recordset[0].TOTAL_SECONDS || "");
        setRefCode(
          response.data.recordset[0].MY_REFERRAL_CODE
            ? response.data.recordset[0].MY_REFERRAL_CODE
            : "0"
        );
        setAmbStats(
          JSON.parse(response.data.recordset[0].AMBASSADOR_STATS || "")
        );
        sessionStorage.setItem("grade", response.data.recordset[0].GRADE || "");
        sessionStorage.setItem(
          "image",
          response.data.recordset[0].USER_PHOTO || ""
        );
      }
    });
    Axios.get(`/user/UserLevelDetails/${userId}`).then((response) => {
      if (response.data.recordset[0]) {
        setCurLevel(response.data.recordset[0].XP_LEVEL || "");
        setNextLevel(response.data.recordset[0].NEXT_LEVEL || "");
        setRequiredPoints(response.data.recordset[0].REQUIRED_POINTS || "");
      }
    });
  }, [Photo]);

  const checkDetails = () => {
    const Mobile = sessionStorage.getItem("usermobile");
    // console.log("user");
    Axios.post("/user/StudentData", {
      EMAIL: Email,
      FIRST_NAME: firstname,
      LAST_NAME: lastname,
      Mobile: Mobile,
      City: City,
      Gender: Gender,
      Grade: 0,
      State: Statee,
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Updated Successfully!!", {
          autoClose: 8000,
          position: toast.POSITION.TOP_CENTER,
        });

        handleBasicClick("tab2");

        // e.preventdefault()
      } else {
        console.log("technical error");
        toast.warning("Update failed, Please try again later!!", {
          autoClose: 8000,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  const changePassword = (e) => {
    e.preventDefault();

    if (Mobile !== null && NewPassword !== null) {
      const payload = {
        PASSWORD: NewPassword,
        MOBILE_NUMBER: Mobile,
      };

      Axios.post("/user/ResetPassword", payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            handleBasicClick("tab1");
            document.getElementById("myInput").value = "";
            document.getElementById("myInput1").value = "";
            toast.success(response.data.message, {
              autoClose: 10000,
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success("Please Try Again to Change Your Password !", {
              autoClose: 10000,
              position: toast.POSITION.TOP_CENTER,
            });
            console.log("technical error- ", response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.success("Please Enter All The Details!", {
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const onFormSubmit = (e) => {
    // e.preventDefault();
    //console.log('files length:-  ' + e.target.files.length)
    const formData = new FormData();
    formData.append("myImage", e.target.files[0]);
    // console.log(formData);
    // console.log("file");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const userId = sessionStorage.getItem("userid");

    Axios.post(`/user/upload/${userId}`, formData, config)
      .then((response) => {
        if (response.status === 200) {
          //console.log('hello')
        }
        // alert("Your profile picture is uploaded");
        SetPhoto(true);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getJSONFile = async (fileName) => {
    //console.log(fileName)
    //const getJSONFilePath = `../Lotties/${fileName}`
    const getJSONFilePath = fileName;
    //Axios.get(`${getJSONFilePath}`).then((response) => {console.log(response.data)})

    const jsonFile = await require(`${getJSONFilePath}`);
    //console.log(jsonFile)
    //return jsonFile
  };
  const Coins = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: coins,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const AmbStatus = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: ambstats,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Clock = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: clock,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Video = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: {
        v: "5.1.18",
        fr: 15,
        ip: 0,
        op: 91,
        w: 40,
        h: 40,
        nm: "1x - 40pt",
        ddd: 0,
        assets: [
          {
            id: "comp_0",
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 0,
                nm: "3x",
                refId: "comp_1",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [60, 60, 0], ix: 2 },
                  a: { a: 0, k: [562.5, 90, 0], ix: 1 },
                  s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                w: 1125,
                h: 180,
                ip: 0,
                op: 660,
                st: 0,
                bm: 0,
              },
            ],
          },
          {
            id: "comp_1",
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "liquid",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.3], y: [1] },
                        o: { x: [0.167], y: [0.167] },
                        n: ["0p3_1_0p167_0p167"],
                        t: 41,
                        s: [0],
                        e: [63],
                      },
                      { t: 58 },
                    ],
                    ix: 10,
                  },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.3, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        n: "0p3_1_0p167_0p167",
                        t: 41,
                        s: [577.558, 63.933, 0],
                        e: [584.308, 32.933, 0],
                        to: [-6.375, -4.20833349227905, 0],
                        ti: [-16.625, 4.83333349227905, 0],
                      },
                      { t: 58 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [19.933, -44.317, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.376, 0.376, -45.098],
                        },
                        n: [
                          "0p3_1_0p167_0p376",
                          "0p3_1_0p167_0p376",
                          "0p3_1_0p167_-45p098",
                        ],
                        t: 41,
                        s: [120, 120, 100],
                        e: [0, 0, 100],
                      },
                      { t: 87 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 1,
                          k: [
                            {
                              i: { x: 0.3, y: 1 },
                              o: { x: 0.167, y: 0.167 },
                              n: "0p3_1_0p167_0p167",
                              t: 41,
                              s: [
                                {
                                  i: [
                                    [-3.138, 0],
                                    [0, -3.138],
                                    [0, 0],
                                    [0.125, 5.567],
                                  ],
                                  o: [
                                    [3.138, 0],
                                    [0, 3.138],
                                    [0, 0],
                                    [-0.07, -3.138],
                                  ],
                                  v: [
                                    [0, -5.683],
                                    [5.683, 0],
                                    [2.75, 10.808],
                                    [-5.683, 0],
                                  ],
                                  c: true,
                                },
                              ],
                              e: [
                                {
                                  i: [
                                    [-3.138, 0],
                                    [1.074, -2.949],
                                    [0, 0],
                                    [0.46, 7.645],
                                  ],
                                  o: [
                                    [3.138, 0],
                                    [-1.772, 4.863],
                                    [0, 0],
                                    [-0.189, -3.133],
                                  ],
                                  v: [
                                    [0, -5.683],
                                    [5.683, 0],
                                    [6.953, 16.877],
                                    [-5.683, 0],
                                  ],
                                  c: true,
                                },
                              ],
                            },
                            { t: 58 },
                          ],
                          ix: 2,
                        },
                        nm: "路径 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.137254901961, 0.247058823529, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "填充 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [19.933, -44.317], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "变换",
                      },
                    ],
                    nm: "椭圆 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 41,
                op: 650,
                st: -3,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Combined Shape 2",
                parent: 7,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: -180, ix: 10 },
                  p: { a: 0, k: [-0.337, -1.674, 0], ix: 2 },
                  a: { a: 0, k: [-32.282, 576.572, 0], ix: 1 },
                  s: { a: 0, k: [19.513, 19.512, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.38, -1.95],
                              [0, 0],
                              [-4.58, -0.73],
                              [0, 0],
                              [-0.16, -1.98],
                              [0, 0],
                              [8.89, 0.45],
                            ],
                            o: [
                              [2.03, 0.13],
                              [0, 0],
                              [0.78, 4.57],
                              [0, 0],
                              [1.9, 0.4],
                              [0, 0],
                              [-0.55, -8.86],
                              [0, 0],
                            ],
                            v: [
                              [0, 0],
                              [4.06, 3.57],
                              [4.07, 3.57],
                              [13.06, 12.47],
                              [13.07, 12.47],
                              [16.58, 16.47],
                              [16.58, 16.38],
                              [0, 0],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "路径 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.137254901961, 0.247058823529, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "填充 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [3214.286, 3214.286], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "变换",
                      },
                    ],
                    nm: "组 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          n: ["0p833_0p833_0p167_0p167"],
                          t: 65,
                          s: [0],
                          e: [100],
                        },
                        { t: 92 },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: "修剪路径 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 650,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Combined Shape",
                parent: 5,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 13, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.5, y: 1 },
                        o: { x: 0.5, y: 0 },
                        n: "0p5_1_0p5_0",
                        t: 40,
                        s: [9.705, -34.411, 0],
                        e: [29.766, -34.661, 0],
                        to: [3.343590259552, -0.04166666790843, 0],
                        ti: [-3.343590259552, 0.04166666790843, 0],
                      },
                      { t: 65 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: { a: 0, k: [6.244, 6.244, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [-0.38, -1.95],
                              [0, 0],
                              [-4.58, -0.73],
                              [0, 0],
                              [-0.16, -1.98],
                              [0, 0],
                              [8.89, 0.45],
                            ],
                            o: [
                              [2.03, 0.13],
                              [0, 0],
                              [0.78, 4.57],
                              [0, 0],
                              [1.9, 0.4],
                              [0, 0],
                              [-0.55, -8.86],
                              [0, 0],
                            ],
                            v: [
                              [0, 0],
                              [4.06, 3.57],
                              [4.07, 3.57],
                              [13.06, 12.47],
                              [13.07, 12.47],
                              [16.58, 16.47],
                              [16.58, 16.38],
                              [0, 0],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "路径 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.137254901961, 0.247058823529, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "填充 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [3214.286, 3214.286], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "变换",
                      },
                    ],
                    nm: "组 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                  {
                    ty: "tm",
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.5], y: [1] },
                          o: { x: [0.5], y: [0] },
                          n: ["0p5_1_0p5_0"],
                          t: 30,
                          s: [100],
                          e: [0],
                        },
                        { t: 64 },
                      ],
                      ix: 2,
                    },
                    o: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.5], y: [1] },
                          o: { x: [0.5], y: [0] },
                          n: ["0p5_1_0p5_0"],
                          t: 30,
                          s: [-84],
                          e: [-132],
                        },
                        { t: 50 },
                      ],
                      ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: "修剪路径 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 650,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 5,
                ty: 3,
                nm: "空 1",
                sr: 1,
                ks: {
                  o: { a: 0, k: 0, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.3, y: 1 },
                        o: { x: 0.167, y: 0.056 },
                        n: "0p3_1_0p167_0p056",
                        t: 0,
                        s: [562.5, 0, 0],
                        e: [562.5, 90, 0],
                        to: [0, 15, 0],
                        ti: [0, -15, 0],
                      },
                      { t: 30 },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 16.667],
                        },
                        n: [
                          "0p833_1_0p167_0p167",
                          "0p833_1_0p167_0p167",
                          "0p833_1_0p167_16p667",
                        ],
                        t: 0,
                        s: [0, 0, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 30,
                        s: [100, 100, 100],
                        e: [100, 100, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 48,
                        s: [100, 100, 100],
                        e: [110, 110, 100],
                      },
                      {
                        i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                        o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                        n: [
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                          "0p833_1_0p167_0",
                        ],
                        t: 59,
                        s: [110, 110, 100],
                        e: [100, 100, 100],
                      },
                      { t: 66 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                ip: 0,
                op: 650,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "三角",
                parent: 5,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.3], y: [1] },
                        o: { x: [0.167], y: [0.083] },
                        n: ["0p3_1_0p167_0p083"],
                        t: 0,
                        s: [720],
                        e: [0],
                      },
                      {
                        i: { x: [0.5], y: [1] },
                        o: { x: [0.167], y: [0] },
                        n: ["0p5_1_0p167_0"],
                        t: 30,
                        s: [0],
                        e: [0],
                      },
                      {
                        i: { x: [0.5], y: [1] },
                        o: { x: [0.5], y: [0] },
                        n: ["0p5_1_0p5_0"],
                        t: 48,
                        s: [0],
                        e: [-380],
                      },
                      {
                        i: { x: [0.656], y: [1] },
                        o: { x: [0.337], y: [0] },
                        n: ["0p656_1_0p337_0"],
                        t: 74,
                        s: [-380],
                        e: [-360],
                      },
                      { t: 92 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [0, 0, 0], ix: 2 },
                  a: { a: 0, k: [250, 250, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 5.333],
                        },
                        n: [
                          "0p3_1_0p167_0p167",
                          "0p3_1_0p167_0p167",
                          "0p3_1_0p167_5p333",
                        ],
                        t: 13,
                        s: [0, 0, 100],
                        e: [32, 32, 100],
                      },
                      { t: 30 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [-9.527, 2.316],
                          [-13.817, 14.242],
                          [6.813, 7.022],
                          [19.942, 4.837],
                          [2.743, -9.589],
                          [0, -10.228],
                          [-2.712, -9.42],
                        ],
                        o: [
                          [19.92, -4.843],
                          [6.812, -7.023],
                          [-13.829, -14.255],
                          [-9.692, -2.351],
                          [-2.68, 9.367],
                          [0, 10.293],
                          [2.714, 9.422],
                        ],
                        v: [
                          [242.952, 292.175],
                          [294.429, 262.677],
                          [294.427, 236.939],
                          [242.896, 207.428],
                          [220.53, 220.236],
                          [216.414, 249.724],
                          [220.581, 279.39],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: "路径 1",
                    mn: "ADBE Vector Shape - Group",
                    hd: false,
                  },
                  {
                    ty: "fl",
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: "填充 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 650,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "main",
                parent: 5,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.5], y: [1] },
                        o: { x: [0.167], y: [0.194] },
                        n: ["0p5_1_0p167_0p194"],
                        t: 47,
                        s: [193],
                        e: [0],
                      },
                      { t: 76 },
                    ],
                    ix: 10,
                  },
                  p: { a: 0, k: [0, 0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.569, 0.569, 0.569], y: [1, 1, 1] },
                        o: { x: [0.217, 0.217, 0.217], y: [0, 0, 0] },
                        n: [
                          "0p569_1_0p217_0",
                          "0p569_1_0p217_0",
                          "0p569_1_0p217_0",
                        ],
                        t: 47,
                        s: [32, 32, 100],
                        e: [33, 37, 100],
                      },
                      {
                        i: { x: [0.644, 0.644, 0.644], y: [0.396, 1, 5.11] },
                        o: { x: [0.306, 0.306, 0.306], y: [0, 0, 0] },
                        n: [
                          "0p644_0p396_0p306_0",
                          "0p644_1_0p306_0",
                          "0p644_5p11_0p306_0",
                        ],
                        t: 64,
                        s: [33, 37, 100],
                        e: [32, 32, 100],
                      },
                      { t: 76 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ind: 0,
                    ty: "sh",
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 61.81],
                          [61.81, 0],
                          [0, -61.81],
                          [-0.142, -2.312],
                          [0, 0],
                          [-12.187, -2.545],
                          [0, 0],
                          [-4.995, -29.216],
                          [0, 0],
                          [-13.473, -0.338],
                          [-0.244, 0],
                        ],
                        o: [
                          [0, -61.81],
                          [-61.811, 0],
                          [0, 2.348],
                          [0, 0],
                          [0.977, 12.692],
                          [0, 0],
                          [29.319, 4.69],
                          [0, 0],
                          [2.524, 12.814],
                          [0.242, 0.006],
                          [61.81, 0],
                        ],
                        v: [
                          [111.917, 0],
                          [0.001, -111.917],
                          [-111.916, 0],
                          [-111.702, 6.99],
                          [-111.725, 6.405],
                          [-89.253, 32.009],
                          [-89.223, 32.018],
                          [-31.703, 88.925],
                          [-31.693, 88.953],
                          [-4.553, 111.826],
                          [0.001, 111.917],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: "路径 2",
                    mn: "ADBE Vector Shape - Group",
                    hd: false,
                  },
                  {
                    ty: "fl",
                    c: {
                      a: 0,
                      k: [1, 0.137254901961, 0.247058823529, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: "填充 1",
                    mn: "ADBE Vector Graphic - Fill",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 650,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "形状图层 4",
                parent: 5,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [0.125, 0, 0], ix: 2 },
                  a: { a: 0, k: [-0.187, -0.033, 0], ix: 1 },
                  s: { a: 0, k: [98, 98, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-19.875, 0],
                              [0, -19.875],
                              [19.875, 0],
                              [0, 19.875],
                            ],
                            o: [
                              [19.875, 0],
                              [0, 19.875],
                              [-19.875, 0],
                              [0, -19.875],
                            ],
                            v: [
                              [0, -35.986],
                              [35.986, 0],
                              [0, 35.986],
                              [-35.986, 0],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "路径 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.419607992733, 0.345097979377, 1, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 0, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        nm: "描边 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.137254901961, 0.247058823529, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "填充 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [-0.187, -0.033], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "变换",
                      },
                    ],
                    nm: "椭圆 1",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 41,
                st: -10,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "形状图层 1",
                parent: 5,
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [0.125, 0, 0], ix: 2 },
                  a: { a: 0, k: [-0.187, -0.033, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.61, 0.61, 0.61], y: [1, 1, 1] },
                        o: {
                          x: [0.281, 0.281, 0.281],
                          y: [0.071, 0.071, -1.095],
                        },
                        n: [
                          "0p61_1_0p281_0p071",
                          "0p61_1_0p281_0p071",
                          "0p61_1_0p281_-1p095",
                        ],
                        t: 69,
                        s: [68, 68, 100],
                        e: [97, 97, 100],
                      },
                      { t: 87 },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        ind: 0,
                        ty: "sh",
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [-19.875, 0],
                              [0, -19.875],
                              [19.875, 0],
                              [0, 19.875],
                            ],
                            o: [
                              [19.875, 0],
                              [0, 19.875],
                              [-19.875, 0],
                              [0, -19.875],
                            ],
                            v: [
                              [0, -35.986],
                              [35.986, 0],
                              [0, 35.986],
                              [-35.986, 0],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: "路径 1",
                        mn: "ADBE Vector Shape - Group",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [0.419607992733, 0.345097979377, 1, 1],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 0, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        nm: "描边 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [1, 0.137254901961, 0.247058823529, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: "填充 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [-0.187, -0.033], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "变换",
                      },
                    ],
                    nm: "椭圆 1",
                    np: 3,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 69,
                op: 650,
                st: 62,
                bm: 0,
              },
            ],
          },
        ],
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 0,
            nm: "3x - 40pt",
            refId: "comp_0",
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [20, 20, 0], ix: 2 },
              a: { a: 0, k: [60, 60, 0], ix: 1 },
              s: { a: 0, k: [33.333, 33.333, 100], ix: 6 },
            },
            ao: 0,
            w: 120,
            h: 120,
            ip: 0,
            op: 660,
            st: 0,
            bm: 0,
          },
        ],
        markers: [],
      },

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };

  const LoadLottie = ({ lottiefile }) => {
    //animationData: success,
    var options = null;
    if (lottiefile !== null && lottiefile !== undefined) {
      options = {
        loop: true,
        autoplay: true,
        animationData: getJSONFile(lottiefile),
        rendererSettings: {
          preserveAspectRatio: "xMidyMid slice",
        },
      };
    }
    const { View } = useLottie(options);
    return View;
  };

  const Refer = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: referral,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const sendDetailsToServer = (e) => {
    if (frndmobile !== null && frndname !== null) {
      const payload = {
        REFERRAL_MOBILE_NUMBER: frndmobile,
        REFERRAL_NAME: frndname,
        REFERRED_USER_ID: userid,
      };
      Axios.post("/user/Referral", payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          //console.log(response.data.result.message)
          if (response.status === 200) {
            Show();

            toast(
              <div
                className="px-2"
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#f9dcc5",
                  backgroundSize: "cover",
                  borderRadius: "14px",
                  alignItems: "center",
                }}
              >
                {/* <div style={{ height: '100px', width: '200px' }}>
                  <Refer />
                </div> */}
                {response.data.result.message}
              </div>,
              {
                // autoClose: 10000,
                position: toast.POSITION.TOP_CENTER,
              }
            );
            document.getElementById("Input").value = "";
            document.getElementById("Input1").value = "";
          } else {
            console.log("Technical Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //console.log('Enter Valid Number')
    }
  };
  return (
    <div style={bgimage} alt="backgroundimage">
      {/* <div style={bgimage} alt="backgroundimage"> */}
      <Navbar />

      <MDBRow className="pt-4 pb-1">
        <MDBCol
          md="3"
          style={{
            // paddingBottom: '40px',
            paddingLeft: "20px",
            fontFamily: "Playfair Display",
          }}
        >
          <MDBCard
            border="gray"
            style={{
              width: "21rem",
              height: "24rem",
              borderRadius: "5px",
            }}
          >
            {/* <Avatar
                  src={`/user/getImages/${image}`}
                  style={{
                    width: " 85%",
                    height: "85%",
                    marginRight: "10%",
                    marginLeft: "10%",
                    marginTop: "5%",
                  }}
                  alt={`${firstname.charAt(0).toUpperCase()}`}
                  roundedCircle
                /> */}
            <MDBDropdown>
              <MDBDropdownToggle
                style={{ cursor: "pointer" }}
                tag="a"
                className="nav-link hidden-arrow d-flex align-items-center"
              >
                {/* <img
                  src={Image}
                  className="rounded-circle "
                  style={{ height: 34 }}
                  alt=""
                /> */}
                {/* <MDBIcon
                  far
                  icon="user-circle"
                  size="2x"
                  className="rounded-circle  "
                  style={{ color: "#05818D" }}
                /> */}
                <div class="container" style={{ height: "202px" }}>
                  <div class="picture-container">
                    <div
                      class="picture"
                      style={{ width: "180px", height: "180px" }}
                    >
                      <div
                        style={{
                          // backgroundImage: `url(${Layer})`,
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <img
                          src={
                            image !== undefined && image !== null
                              ? `/user/getImages/${image}`
                              : "/user/getImages/user.png"
                          }
                          //  src={`/user/getImages/${image}`}
                          class="picture-src"
                          id="wizardPicturePreview"
                          title=""
                          alt={`${firstname.charAt(0).toUpperCase()}`}
                        />
                        {/* <input
                            type="file"
                            // id="wizard-picture"
                            id="formFileSm"
                            onChange={onFormSubmit}
                          ></input> */}
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={onFormSubmit}
                  style={{ display: "none" }}
                />
              </MDBDropdownToggle>
              <MDBDropdownMenu
                style={{ zIndex: 1060, fontFamily: "Playfair Display" }}
              >
                <MDBDropdownItem>
                  <MDBDropdownLink onClick={handleClick}>
                    Upload Photo
                  </MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBDropdownLink onClick={toggleShow}>
                    Take Photo
                  </MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            {/* <MDBCardBody align="right" padding="0px"></MDBCardBody> */}
            {/* <MDBListGroup flush style={{ fontFamily: 'Playfair Display' }}>
              <MDBListGroupItem>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <span style={{ width: '70px' }}>
                    <Coins />
                  </span>

                  <div
                    className="py-4"
                    style={{
                      paddingLeft: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {coin} Coins Earned
                  </div>
                </div>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span
                    style={{
                      height: '40px',
                      width: '50px',
                      paddingLeft: '5px',
                    }}
                  >
                    <Clock />
                  </span>

                  <div
                    style={{
                      paddingLeft: '35px',
                      paddingTop: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    {since} Days Ago
                  </div>
                </div>
              </MDBListGroupItem>
              <MDBListGroupItem >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ height: '60px', width: '60px' }}>
                    <Video />
                  </span>

                  <div
                    style={{
                      paddingLeft: '20px',
                      paddingTop: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    {Math.round(videos / 60)} Minutes Viewed
                  </div>
                </div>
              </MDBListGroupItem>
            </MDBListGroup> */}
            <MDBCardFooter
              className="py-4"
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <div
                className="py-3"
                style={{
                  color: "#000099",
                  fontFamily: "Playfair Display",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                You are a,
                <span
                  style={{
                    fontFamily: "Playfair Display",
                    fontWeight: "bold",
                    color: "#a837f2",
                    fontSize: "16px",
                  }}
                >
                  {CurLevel === null ||
                  CurLevel === undefined ||
                  CurLevel === ""
                    ? " Beginner"
                    : " " + CurLevel}
                  !!
                </span>
                <br />
                Earn{" "}
                <span
                  style={{
                    fontFamily: "Playfair Display",
                    fontWeight: "bold",
                    color: "#a837f2",
                    fontSize: "16px",
                  }}
                >
                  {RequiredPoints}
                </span>{" "}
                More XP To Become{" "}
                <span
                  style={{
                    fontFamily: "Playfair Display",
                    fontWeight: "bold",
                    color: "#a837f2",
                    fontSize: "16px",
                  }}
                >
                  {NextLevel}
                </span>
                <br />
                {/* <a
                  onClick={ShowQRCode}
                  href="#"
                  style={{ fontSize: "12px" }}
                  className="float-end pt-2"
                >
                  Show My QR Code
                </a> */}
              </div>
            </MDBCardFooter>
          </MDBCard>
        </MDBCol>
        <MDBCol
          md="6"
          className="pb-2"
          style={{ width: "70%", marginLeft: "4vh" }}
        >
          <MDBCard border="gray" className="px-2" style={{ height: "24rem" }}>
            <MDBTabs className="mb-3">
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab1")}
                  active={basicActive === "tab1"}
                >
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontWeight: "bold",
                      color: "#a837f2",
                    }}
                  >
                    About Me
                  </span>
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab2")}
                  active={basicActive === "tab2"}
                >
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontWeight: "bold",
                      color: "#a837f2",
                    }}
                  >
                    Edit Profile
                  </span>
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleBasicClick("tab3")}
                  active={basicActive === "tab3"}
                >
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontWeight: "bold",
                      color: "#a837f2",
                    }}
                  >
                    Change Password
                  </span>
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
              <MDBTabsPane
                show={basicActive === "tab2"}
                style={{ fontFamily: "Playfair Display" }}
              >
                <MDBCardText>
                  <form>
                    <MDBRow className="mb-4 ">
                      <MDBCol size="6" className=" col-example">
                        <MDBInput
                          className="px-1"
                          focus
                          placeholder="First Name"
                          style={{
                            backgroundColor: "#FFFFFF",
                          }}
                          size="sm "
                          required
                          width="100%"
                          variant="outlined"
                          label="First Name"
                          name="FirstName"
                          value={firstname}
                          onChange={(event) => {
                            setfirstname(event.target.value);
                          }}
                        />
                      </MDBCol>
                      <MDBCol size="1"></MDBCol>
                      <MDBCol size="5" className=" col-example">
                        <MDBInput
                          label="Last Name"
                          name="LastName"
                          placeholder="Last Name"
                          variant="outlined"
                          width="80%"
                          size="sm"
                          style={{ backgroundColor: "#FFFFFF" }}
                          required
                          value={lastname}
                          onChange={(event) => {
                            setlastname(event.target.value);
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {/* <MDBCol size="6" className=" col-example"> */}
                      {/* <select
                      className="mb-4" disabled
                      style={{
                        backgroundColor: '#ffffff',
                        variant: 'outlined',
                        width: '100%',
                        height: '28px',
                        borderRadius: '5px',
                        borderColor: '#B2BEB5',
                        fontSize: '.775rem',
                        color: '#757575',
                        paddingLeft: '7px',
                        fontFamily: 'Playfair Display',
                      }}
                      value={sessionStorage.getItem('grade')}
                      onChange={(event) => {
                        setGrade(event.target.value)
                      }}
                    >
                      {grade.map((option) => (
                        <option key={option.value} value={option.Value}>
                          {' '}
                          {option.Text}
                        </option>
                      ))}
                    </select> */}
                      {/* </MDBCol> */}
                      {/* <MDBCol size="1"></MDBCol> */}
                      <MDBCol size="5" className=" col-example">
                        <select
                          className="mb-4 pl-1"
                          style={{
                            backgroundColor: "#ffffff",
                            variant: "outlined",
                            width: "100%",
                            height: "28px",
                            borderRadius: "5px",
                            borderColor: "#B2BEB5",
                            fontSize: ".775rem",
                            color: "#757575",
                            paddingLeft: "7px",
                            fontFamily: "Playfair Display",
                          }}
                          block="true"
                          value={Gender}
                          onChange={(event) => {
                            setGender(event.target.value);
                          }}
                        >
                          {Qualification.map((option) => (
                            <option key={option.value} value={option.Value}>
                              {" "}
                              {option.Text}
                            </option>
                          ))}
                        </select>
                      </MDBCol>
                    </MDBRow>
                    <MDBInput
                      className="mb-4 "
                      placeholder="Email"
                      label="Email"
                      name="Email"
                      variant="outlined"
                      size="sm"
                      style={{ backgroundColor: "#FFFFFF" }}
                      required
                      value={Email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    <MDBInput
                      className="mb-4 "
                      label="City"
                      name="City"
                      variant="outlined"
                      size="sm"
                      style={{ backgroundColor: "#FFFFFF" }}
                      required
                      value={City}
                      onChange={(event) => {
                        setCity(event.target.value);
                      }}
                    />
                    <select
                      className="mb-4 "
                      style={{
                        backgroundColor: "#ffffff",
                        variant: "outlined",
                        width: "100%",
                        height: "28px",
                        borderRadius: "5px",
                        borderColor: "#B2BEB5",
                        fontSize: ".775rem",
                        color: "#757575",
                        paddingLeft: "7px",
                        fontFamily: "Playfair Display",
                      }}
                      value={Statee}
                      onChange={(event) => {
                        setStatee(event.target.value);
                      }}
                    >
                      {state.map((option) => (
                        <option key={option.value} value={option.Value}>
                          {" "}
                          {option.Text}
                        </option>
                      ))}
                    </select>

                    <MDBBtn
                      size="md"
                      className="mb-4"
                      type="button"
                      block
                      style={{
                        backgroundColor: "#a837f2",
                        Width: "20px",
                        letterSpacing: "1.5px",
                      }}
                      onClick={() => checkDetails()}
                    >
                      Submit
                    </MDBBtn>
                  </form>
                </MDBCardText>
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === "tab1"}>
                {/* <ul>
                      <li>
                        {firstname} {lastname}
                      </li>

                      <li>{Grade}th Grade</li>
                      <li>
                        {City},{Statee}
                      </li>
                      {Email}
                    </ul> */}
                <MDBListGroup
                  flush
                  style={{
                    minWidth: "22rem",
                    fontSize: "15px",
                    fontFamily: "Playfair Display",
                  }}
                >
                  <MDBListGroupItem>
                    {" "}
                    {firstname} {lastname}
                  </MDBListGroupItem>
                  <MDBListGroupItem>{Grade}</MDBListGroupItem>
                  <MDBListGroupItem>{myNumber}</MDBListGroupItem>
                  {/* {City !== null && City !== undefined ? (
                    <MDBListGroupItem>
                      {City}, {Statee}
                    </MDBListGroupItem>
                  ) : (
                    <></>
                  )} */}
                  {Email !== null && Email !== undefined ? (
                    <MDBListGroupItem>{Email}</MDBListGroupItem>
                  ) : (
                    <></>
                  )}
                </MDBListGroup>
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === "tab3"}>
                <form>
                  <MDBInput
                    className="mb-4 "
                    placeholder="Mobile Number"
                    label="Mobile Number"
                    name="Mobile Number"
                    variant="outlined"
                    size="sm"
                    style={{ backgroundColor: "#FFFFFF" }}
                    required
                    value={Mobile}
                  />
                  <MDBInput
                    autoFocus={true}
                    className="mb-4 "
                    type="password"
                    id="myInput"
                    label="Old Password"
                    name="Old Pin"
                    variant="outlined"
                    size="sm"
                    style={{ backgroundColor: "#FFFFFF" }}
                    required
                    onChange={(e) => {
                      if (e.target.value.match("^\\d{4}$") != null) {
                        setOldPassword(e.target.value);
                        setErrOldPassword("");
                      } else {
                        setErrOldPassword("Please enter 4 digit Password");
                      }
                    }}
                  />

                  <MDBTypography style={formval}>
                    {ErrOldPassword}
                  </MDBTypography>
                  <MDBInput
                    className="mb-4"
                    id="myInput1"
                    label="New Password"
                    name="New Pin"
                    type="password"
                    variant="outlined"
                    size="sm"
                    style={{ backgroundColor: "#FFFFFF" }}
                    required
                    onChange={(e) => {
                      if (e.target.value.match("^\\d{4}$") != null) {
                        setNewPassword(e.target.value);
                        setErrNewPassword("");
                      } else {
                        setErrNewPassword("Please enter 4 digit Password");
                      }
                    }}
                  />
                  <MDBBtn
                    type="submit"
                    size="sm"
                    block
                    style={{
                      backgroundColor: "#a837f2",
                      Width: "20px",
                      letterSpacing: "1.5px",
                    }}
                    onClick={(e) => changePassword(e)}
                  >
                    submit
                  </MDBBtn>
                </form>
              </MDBTabsPane>
            </MDBTabsContent>
          </MDBCard>
        </MDBCol>
        {/* <MDBCol
          md="3" className="pb-2"
          style={{
             paddingLeft: '20px',
            //  paddingBottom: '40px',
            fontFamily: 'Playfair Display',
          }}
        >
          <a onClick={Show} href='#'>
            <MDBCard
              border="gray"
              style={{
                width: '20rem', height: '24rem',
                borderRadius: '5px',
              }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <MDBCardHeader
                    className="py-4"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      //paddingBottom: '10px',
                    }}
                  >
                    You Can Earn Bonus Coins By Referring a Friend
                  </MDBCardHeader>
                  <span
                  // style={{
                  //   height: '40px',
                  //   width: '70px',
                  //   paddingLeft: '5px',
                  // }}
                  >
                    <Refer />
                  </span>

                  <MDBCardFooter
                    className="py-4"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    TAP TO REFER A FRIEND
                  </MDBCardFooter>
                </div>
              </div>
            </MDBCard>
          </a>
        </MDBCol> */}
      </MDBRow>
      <MDBRow className="pt-1 pb-3">
        <MDBCol
          md="3"
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontFamily: "Playfair Display",
            width: "33.3%",
          }}
        >
          <MDBCard
            border="gray"
            style={{
              width: "95%",
              height: "145px",
              //backgroundColor: 'rgba(254, 61, 11, 0.2)',
              fontFamily: "Playfair Display",
              fontSize: "1rem",
            }}
            className="bg-success p-2 text-success bg-opacity-25" //className = 'gradient-green'
          >
            <MDBCardBody
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <span style={{ width: "70px" }}>
                  <Coins />
                </span>

                <div
                  className="py-4"
                  style={{
                    paddingLeft: "20px",
                    fontWeight: "bold",
                    //color:'#a837f2'
                  }}
                >
                  You have Earned {coin} Coins
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol
          md="3"
          style={{
            paddingBottom: "10px",
            fontFamily: "Playfair Display",
            width: "33.3%",
          }}
        >
          {" "}
          <MDBCard
            border="gray"
            style={{
              width: "95%",
              height: "145px",
              //backgroundColor: 'rgba(0, 150, 65,0.2)',
              fontFamily: "Playfair Display",
              fontSize: "1rem",
            }}
            className="bg-danger p-2 text-danger bg-opacity-25" //className = 'gradient-orange'
          >
            <MDBCardBody
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    height: "40px",
                    width: "100px",
                    paddingLeft: "5px",
                  }}
                >
                  <Clock />
                </span>

                <div
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    fontWeight: "bold",
                    // color:'#009641'
                  }}
                >
                  {since > 0 ? (
                    <span>Member since {since} day(s)</span>
                  ) : (
                    <span>
                      {" "}
                      Welcome Onboard! We are delighted to have you as a member{" "}
                    </span>
                  )}
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol
          md="3"
          style={{
            paddingBottom: "10px",
            fontFamily: "Playfair Display",
            width: "33.3%",
          }}
        >
          {" "}
          <MDBCard
            border="gray"
            style={{
              width: "95%",
              height: "145px",
              //backgroundColor: 'rgba(162, 26, 91,0.2)',
              fontFamily: "Playfair Display",
              fontSize: "1rem",
            }}
            className="bg-primary p-2 text-primary bg-opacity-25" // className = 'gradient-blue'
          >
            <MDBCardBody
              style={{
                paddingLeft: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ height: "60px", width: "60px" }}>
                  <Video />{" "}
                </span>
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "15px",
                    fontWeight: "bold", //, color:'#fff' //color:'#A21A5B'
                  }}
                >
                  {Math.round((videos ? videos : 0) / 60)} Minutes Viewed{" "}
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Profile</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <WebcamCapture />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* <MDBFooter className="fixed">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position:'absolute',
            left:'0',
            bottom:'0',
            right:'0'
          }}
        >
          <MDBCol
            size="10"
            className="text-center p-3"
            style={{
              fontSize: '10px',
              // backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            © 2022 Copyright:
            <a className="text-black" href="http://lmsdev.padhobadho.com/">
              PadhoBadho
            </a>
          </MDBCol>
          <MDBCol
            size="2"
            //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
          >
            <section
              className="float-end "
              style={{
                paddingTop: '5px',
                paddingRight: '10px',
                fontSize: '10px',
              }}
            >
              <a className="  btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="facebook-f" />
              </a>
              <a
                className=" btn-floating text-black "
                href="#!"
                role="button"
                size="10px"
              >
                <MDBIcon fab icon="instagram" />
              </a>

              <a className=" btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="linkedin-in" />
              </a>

            </section>
          </MDBCol>
        </div>
        <MDBDropdownDivider></MDBDropdownDivider>
      </MDBFooter> */}

      <MDBModal show={Modal} setShow={Modal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle
                style={{ color: "#000099", fontFamily: "Playfair Display" }}
              >
                Refer a Friend
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={Show}
                style={{
                  backgroundColor: "#a837f2",
                  Width: "20px",
                  letterSpacing: "1.5px",
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <div
                  style={{
                    fontFamily: "PlayfairDisplay",
                    fontWeight: "bold",
                    fontSize: "18px",
                    padding: "15px",
                  }}
                >
                  Refer a Friend and Earn Bonus Coins, which can be Reedemable
                  to Enroll into a Paid Course
                  {/* Our ambition is that Education/Studies plus Skills combination reaches every doorstep. Each village, district and every corner of our country. We aspire that you build a capacity to live confidently in this 21st Century. */}
                </div>
                <MDBInput
                  // label="Mobile Number"
                  autoFocus
                  id="Input"
                  placeholder="Mobile Number"
                  class="borderr"
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    height: "50px",
                    paddingLeft: "7px",
                  }}
                  // size="lg"
                  required
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                />
                <br />
                <MDBInput
                  placeholder="Name"
                  class="borderr"
                  id="Input1"
                  style={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    height: "50px",
                    paddingLeft: "7px",
                  }}
                  // size="lg"
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <br />
                <MDBBtn
                  type="submit"
                  size="lg"
                  block
                  style={{
                    backgroundColor: "#a837f2",
                    borderRadius: "10px",
                    letterSpacing: "1.5px",
                    fontFamily: "Playfair Display",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => sendDetailsToServer(e)}
                >
                  Submit
                </MDBBtn>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={SQRCode} setShow={SQRCode} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle
                style={{ color: "#000099", fontFamily: "Playfair Display" }}
              >
                My QR Code
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={ShowQRCode}
                style={{
                  backgroundColor: "#a837f2",
                  Width: "20px",
                  letterSpacing: "1.5px",
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <div
                  style={{
                    fontFamily: "PlayfairDisplay",
                    fontWeight: "bold",
                    fontSize: "18px",
                    padding: "15px",
                  }}
                >
                  Ask your Friend to Scan this QR code while Registering & you
                  will Earn bonus Coins
                </div>
                <div
                  className="centerFlex"
                  style={{ width: "100%", padding: "15px" }}
                >
                  {RefCode !== "0" ? (
                    <QRCode
                      title="My QR Code"
                      value={RefCode}
                      bgColor="white"
                      fgColor="black"
                    />
                  ) : (
                    <div
                      style={{
                        fontFamily: "PlayfairDisplay",
                        fontSize: "18px",
                        padding: "15px",
                      }}
                    >
                      Please contact support to generate a referral code
                    </div>
                  )}
                </div>
                {/* <MDBBtn
                  type="submit"
                  size="lg"
                  className ="float-end"
                  style={{
                    backgroundColor: '#a837f2',
                    borderRadius: '10px',
                    letterSpacing: '1.5px',
                    fontFamily: 'Playfair Display',
                    fontWeight:'bold'
                  }}
                  onClick={(e) => sendDetailsToServer(e)}
                >
                  <MDBIcon fas icon="share-alt-square" />
                </MDBBtn> */}
                {/* <MDBBtn floating color='danger' size='lg' tag='a'>
              <MDBIcon fas icon="share-alt-square" />
              </MDBBtn> */}

                {/* <MDBBtn tag='a' color='none' className='m-1 float-end' style={{ color: '#dd4b39', padding:'10px' }}>
            <MDBIcon fas icon="share-alt" size='lg'/>
          </MDBBtn> */}
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export default EditableProfile;
