import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBIcon,
  MDBNavbarLink,
  MDBBadge,
  MDBTooltip,
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBCol,
  MDBModalBody,
} from "mdb-react-ui-kit";
import Avatar from "@mui/material/Avatar";
import Image from "../../assets/Ellipse.png";
import logo from "../../assets/lst-logo.jpeg";
import streak from "../../assets/Streak_fire.png";
import Layer from "../../../src/assets/Image 2.png";
import icon from "../../assets/moneyicon.png";
import backgroundImage from "../../assets/Background.png";
import { useHistory } from "react-router-dom";
import { color } from "@mui/system";
// const score = sessionStorage.getItem("score");
// console.log(score);

export default function Navbar(Coins, quizCompleted) {
  const [data, setData] = useState([]);
  const [Score, setScore] = useState();
  const [Level, setLevel] = useState();
  const [Streak, setStreak] = useState();

  const [Photo, setPhoto] = useState();
  const [username, setUsername] = useState();
  sessionStorage.setItem("level", Level);
  const GradeId = sessionStorage.getItem("grade");
  const firstName = sessionStorage.getItem("username");
  const lastName = sessionStorage.getItem("lastName");
  const mobileNumber = sessionStorage.getItem("mobile");
  const [schoolName, setSchoolName] = useState("");
  const SchoolValue = sessionStorage.getItem("schoolName");
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  //  const TestUrl = sessionStorage.getItem('testUrl');
  //      setTestUrl(TestUrl);
  //      console.log('storeTestUrl',TestUrl);

  let history = useHistory();

  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
    // history.push("/");
  };

  const bgimage = {
    backgroundImage: `url(${backgroundImage})`,
    height: "100%",
    backgroundsize: "cover",
  };

  useEffect(() => {
    // console.log("Checking school name : ",SchoolValue);
    const UserId = sessionStorage.getItem("userid");
    //console.log(UserId);
    Axios.get(`/user/Score/${UserId}`).then((response) => {
      setData(response.data.recordset);
      //console.log(response.data.recordset);
      setScore(response.data.recordset[0].COINS);
      setPhoto(response.data.recordset[0].USER_PHOTO);
      setUsername(response.data.recordset[0].FIRST_NAME);
      setLevel(response.data.recordset[0].XP_LEVEL);
      setStreak(response.data.recordset[0].XP_POINTS);
    });

    // Axios.get('/user/SchoolsList').then((response) => {
    //   console.log(`School List (response)`,response.data.recordset)
    //   setOptions(response.data.recordset)

    // })
  }, [Coins, quizCompleted]);

  const handleClick = () => {
    if (SchoolValue != null && SchoolValue != "null" && SchoolValue != "") {
      //console.log("storeSName",SchoolValue)
      amifyRegister();
    } else {
      setShow(true);
    }
  };

  const handleSchoolChange = (school) => {
    setSelectedSchool(school);
    setDropdownOpen(false);
  };

  const handleIframe = (testUrlLink) => {
    //console.log("coming in iframe")
    window.location.href = testUrlLink;
  };

  //   function amifyRegister() {

  //    if( SchoolValue === null || SchoolValue === 'null' || SchoolValue === ''){
  //      sessionStorage.setItem('schoolName', schoolName);

  //   }
  //   else{
  //      sessionStorage.setItem('schoolName', SchoolValue);
  //      setSchoolName(SchoolValue);
  //   }

  //     const payload = {
  //       "student_name": firstName + ' ' + lastName,
  //       "class": GradeId,
  //       "mobile_number": mobileNumber,
  //       "partner_id": "aimify_padho_baho",
  //      "school_name": schoolName
  //    }
  //     Axios.post('/user/registerAmify', payload)
  //      .then((res) => {
  //        // console.log("res",res)
  //         if(res.status === 200) {
  //           //console.log("coming to if", res.data.result.url)
  //             // setUrl(res.data.response.url)

  //             const data = {
  //               "userId" : sessionStorage.getItem('userid'),
  //               "url" : res.data.result.url,
  //               "schoolName" : schoolName
  //             }
  //             //console.log("data",data)

  //           Axios.post('/user/storeTestUrl',data)
  //            .then((resp) => {
  //              // console.log('res',resp)
  //              handleIframe(res.data.result.url)
  //           })
  //           .catch((error) => {
  //             console.error('Error storing test URL:', error);
  //          });+

  //       }
  //     })
  //     .catch((error) => {
  //           console.error('Error registering with Amify:', error);
  //         });

  //  }

  function amifyRegister() {
    if (!selectedSchool) {
      // No school is selected, handle this case accordingly
      if (
        SchoolValue === null ||
        SchoolValue === "null" ||
        SchoolValue === ""
      ) {
        sessionStorage.setItem("schoolName", schoolName);
      } else {
        sessionStorage.setItem("schoolName", SchoolValue);
        setSchoolName(SchoolValue);
        // Add your API call here for the selected school
        const payload = {
          student_name: firstName + " " + lastName,
          class: GradeId,
          mobile_number: mobileNumber,
          partner_id: "aimify_padho_baho",
          school_name: SchoolValue,
        };
        Axios.post("/user/registerAmify", payload)
          .then((res) => {
            if (res.status === 200) {
              const data = {
                userId: sessionStorage.getItem("userid"),
                url: res.data.result.url,
                schoolName: SchoolValue,
              };
              Axios.post("/user/storeTestUrl", data)
                .then((resp) => {
                  handleIframe(res.data.result.url);
                })
                .catch((error) => {
                  console.error("Error storing test URL:", error);
                });
            }
          })
          .catch((error) => {
            console.error("Error registering with Amify:", error);
          });
      }
      return;
    }

    // School is selected, proceed with registration
    const payload = {
      student_name: firstName + " " + lastName,
      class: GradeId,
      mobile_number: mobileNumber,
      partner_id: "aimify_padho_baho",
      school_name: selectedSchool.SCHOOLNAME,
    };

    console.log("payload", payload);

    Axios.post("/user/registerAmify", payload)
      .then((res) => {
        if (res.status === 200) {
          const data = {
            userId: sessionStorage.getItem("userid"),
            url: res.data.result.url,
            schoolName: selectedSchool.SCHOOLNAME,
          };

          console.log("data", data);

          Axios.post("/user/storeTestUrl", data)
            .then((resp) => {
              handleIframe(res.data.result.url);
            })
            .catch((error) => {
              console.error("Error storing test URL:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error registering with Amify:", error);
      });
  }

  return (
    <MDBNavbar expand="lg" light>
      <MDBContainer fluid>
        <a href="/Student" style={{marginRight:'0px'}}>
          <MDBNavbarBrand>
            <img
              src={logo}
              style={{ height: "90px", marginLeft: "1rem" ,marginRight: "0px"}}
              alt="mySkillforge"
            />
          </MDBNavbarBrand>
        </a>
        <MDBNavbarNav style={{ marginLeft: "1.2rem" }}>
          <MDBNavbarItem>
            <MDBNavbarLink
              style={{
                fontSize: "30px",
                color: "#D24896",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
            >
              MySkillForge
            </MDBNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>

        <form style={{ display: "flex", flexDirection: "row" }}>
          <MDBNavbarItem className="d-flex mx-3 my-3">
            <a href="#!">
              <MDBTooltip
                tag="a"
                title={
                  <span>Level Up By Earning Coins and Experience Points</span>
                }
              >
                <MDBIcon
                  fas
                  icon="medal"
                  size="lg"
                  className="rounded-circle my-3  "
                  style={{ color: "#0000ff" }}
                />
              </MDBTooltip>
              {/* <div style={{ fontSize: '10px', color: '#1F594A' }}>Sipahi</div> */}
              <MDBBadge color="danger" notification pill>
                {Level !== null && Level !== undefined ? Level : "Beginner"}
              </MDBBadge>
            </a>
          </MDBNavbarItem>
          <MDBNavbarItem className="d-flex mx-3 my-3">
            <a href="#!">
              <MDBTooltip
                tag="a"
                title={<span>Complete Lessons and Earn Redeemable Coins</span>}
              >
                <MDBIcon
                  fas
                  icon="coins"
                  size="lg"
                  className="rounded-circle my-3 mx-1 "
                  style={{ color: "orange" }}
                />
              </MDBTooltip>
              <MDBBadge color="danger" notification pill>
                {Score !== null && Score !== undefined ? Score : "0"}
              </MDBBadge>
            </a>
          </MDBNavbarItem>
          <MDBNavbarItem className="d-flex mx-3 my-3">
            <a href="#!">
              <MDBTooltip
                tag="a"
                title={<span>Learn Every Day and Build Your Streak</span>}
              >
                <MDBIcon
                  fab
                  icon=" fa-gripfire"
                  size="2x"
                  className="  bg-opacity-75 rounded-circle pt-1 pb-3 mx-1 "
                  style={{ color: "#FE3D0B" }}
                />
              </MDBTooltip>
              <MDBBadge color="danger" notification pill>
                {Streak !== null && Streak !== undefined ? Streak : "0"}
              </MDBBadge>
            </a>
          </MDBNavbarItem>
          <MDBNavbarItem className="d-flex ">
            <MDBDropdown>
              <MDBDropdownToggle
                style={{ cursor: "pointer" }}
                tag="a"
                className=" hidden-arrow d-flex align-items-center mx-3 pt-0"
              >
                <div
                  style={{
                    // backgroundImage: `url(${Layer})`,
                    backgroundSize: "cover",
                    backgroundposition: "center",
                    backgroundrepeat: "no-repeat",
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <Avatar
                    height="40px"
                    src={
                      Photo !== undefined && Photo !== null
                        ? `/user/getImages/${Photo}`
                        : "/user/getImages/user.png"
                    }
                    // src={`/user/getImages/${Photo}`}
                    // className="rounded-circle  "
                    style={{
                      height: "60px",
                      width: "60px",
                      padding: "1px",
                      // paddingTop:"1px",
                      // paddingLeft:"1px",
                      // paddingBottom:"0.1px",
                      // paddingRight:"0.1px",
                      // backgroundColor: "#999999",

                      // border: "2px solid #cccccc",
                      color: " #ffffff",
                    }}
                    alt={`${username}`}
                  />
                </div>
              </MDBDropdownToggle>
              <MDBDropdownMenu style={{ zIndex: 1060 }}>
                <MDBDropdownItem>
                  <MDBDropdownLink href="/EditProfile">
                    My profile
                  </MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  {/* <MDBDropdownLink onClick={handleClick} >
                    Scholarship Test
                  </MDBDropdownLink> */}
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBDropdownLink onClick={logout}>Logout</MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </form>

        <MDBModal staticBackdrop show={show} setShow={setShow} tabIndex="-1">
          <MDBModalDialog size="md-down" centered>
            <MDBModalContent>
              <MDBModalBody>
                <MDBCol>
                  <div className="d-flex align-items-center justify-content-between">
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "28px",
                        color: "#FE3D0B",
                        textAlign: "center",
                      }}
                    >
                      Amify Test
                    </span>
                    <MDBIcon
                      fas
                      icon="times"
                      className="d-flex align-items-end"
                      onClick={() => setShow(false)}
                    />
                  </div>

                  <div
                    style={{
                      fontFamily: "PlayfairDisplay",
                      fontWeight: "bold",
                      fontSize: "18px",
                      padding: "15px",
                      textAlign: "justify",
                    }}
                  >
                    Please Complete the form
                  </div>
                  <form>
                    <MDBInput
                      autoFocus
                      class="borderr"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "50px",
                        paddingBottom: "18px",
                        paddingLeft: "7px",
                      }}
                      tabIndex="1"
                      // size="lg"
                      required
                      label="First Name"
                      name="FirstName"
                      value={firstName + " " + lastName}
                    />

                    <MDBInput
                      autoFocus
                      class="borderr"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "50px",
                        paddingBottom: "18px",
                        paddingLeft: "7px",
                      }}
                      tabIndex="1"
                      // size="lg"
                      required
                      label="Grade"
                      name="Grade"
                      value={GradeId}
                      className="form-control"
                    />

                    <MDBInput
                      autoFocus
                      class="borderr"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "50px",
                        paddingBottom: "18px",
                        paddingLeft: "7px",
                      }}
                      tabIndex="1"
                      // size="lg"
                      required
                      label="Mobile Number"
                      name="Mobile Number"
                      value={mobileNumber}
                      className="form-control"
                    />

                    {/* <MDBInput
                               autoFocus
                               placeholder='Enter School Name'
                                class="borderr"
                                style={{
                                  backgroundColor: '#FFFFFF',
                                  width: '100%',
                                  height: '50px',
                                  paddingBottom: '18px',paddingLeft: '7px',
                                }}
                                tabIndex='1' 
                                // size="lg"
                                required
                              label="School Name"
                              //name="School Name"
                              value={schoolName}
                                onChange={(e) => {setSchoolName(e.target.value)}}
                           /> */}

                    <MDBInput
                      class="borderr"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "50px",
                        paddingBottom: "18px",
                        paddingLeft: "7px",
                      }}
                      tabIndex="1"
                      required
                      readOnly
                      value={
                        selectedSchool
                          ? selectedSchool.SCHOOLNAME
                          : "Select School"
                      }
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                      icon="angle-down"
                      iconClassName="dropdown-icon"
                    />
                    {dropdownOpen && (
                      <div>
                        <input
                          type="text"
                          placeholder="Search"
                          style={{
                            width: "100%",
                            padding: "8px 12px",
                            border: "none",
                            borderBottom: "1px solid #ccc",
                            outline: "none",
                          }}
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {options
                          .filter((school) =>
                            school.SCHOOLNAME.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          )
                          .map((school) => (
                            <div
                              key={school.SCHOOLNAME}
                              onClick={() => {
                                handleSchoolChange(school);
                              }}
                              style={{
                                padding: "8px 12px",
                                cursor: "pointer",
                              }}
                            >
                              {school.SCHOOLNAME}
                            </div>
                          ))}
                      </div>
                    )}

                    <br />
                    <div className="d-flex align-items-center justify-content-center">
                      <MDBBtn
                        type="button"
                        size="lg"
                        //className='align-items-center'
                        style={{
                          backgroundColor: "#FE3D0B",
                          Width: "20px",
                          letterSpacing: "1.5px",
                        }}
                        onClick={() => {
                          amifyRegister();
                        }}
                      >
                        Register
                      </MDBBtn>
                      {/* {iframeUrl && (
                                     <iframe src={iframeUrl} title="API URL" onLoad={handleIframeLoad} style={{ display: 'none' }} />
                                  )} */}
                    </div>
                  </form>
                </MDBCol>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </MDBNavbar>
  );
}
