import React from "react";
import "./Menu/Navbar.css";
import sidebar from "../Student/Menu/SideBar";
import Navbar from "./Pages/navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Leaderboard from "./Pages/Leaderboard";
import Marketplace from "./Pages/Marketplace";
import TimeTable from "./Pages/TimeTable";
import Notifications from "./Pages/Notifications";
import AllCourses from "./Pages/Courses";
import CourseData from "./Pages/CourseData";
import Profile from "./Pages/Profile";
import Lessons from "./Pages/lessons";
import Sidebar from "../Student/Menu/SideBar";
import Description from "./Pages/CourseDescrption";
import Image from "../../src/assets/Background.png";
import EditableProfile from "./Pages/Editableprofile";

function Student() {
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundsize: "cover",
  };
  return (
    <>
      <Router>
        <Navbar />
        {/* <div style={bgimage} alt="backgroundimage"> */}
        <div>
          {/* <Sidebar> */}
          <Switch>
            {/* <Route path="/Profileee" exact component={EditableProfile} /> */}
            <Route path="/Leaderboard" component={Leaderboard} />
            <Route path="/Marketplace" component={Marketplace} />
            <Route path="/TimeTable" component={TimeTable} />
            {/* <Route path="/Allcourses" component={Lessons} /> */}
            <Route path="/" component={CourseData} />
            {/* <Route path="/Description" component={Description} />
            <Route path="/Allcourses" exact component={Lessons} /> */}
            <Route path="/Notifications" component={Notifications} />
          </Switch>
          {/* </Sidebar> */}
        </div>
      </Router>
    </>
  );
}

export default Student;
