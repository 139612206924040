import React from "react";
import Navbar from "./navbar.js";
import "./Course.css";
import Image from "../../assets/Background.png";
import Accordion from "react-bootstrap/Accordion";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import calendar from "../Lotties/calendar.json";
import Viewtime from "../Lotties/Viewtime.json";
import goldcoin from "../Lotties/goldcoin.json";
import goldcoinold from "../Lotties/goldcoinold.json";
import enrollnow from "../Lotties/enrollnow.json";
import Imagecard from "../../assets/Imagecard1.png";
import Imagecards from "../../assets/Imagecard2.png";
import Greencard from "../../assets/padho.png";
import Orangecard from "../../assets/badho.png";
import queryString from "query-string";
import PDFViewer from "pdf-viewer-reactjs";
import { useLottie } from "lottie-react";
import { useLocation } from "react-router-dom";
import animationData from "../Lotties/SuccessLottie.json";
//import SuccessLottie from "../Lotties/SuccessLottie";
import { withRouter } from "react-router-dom";
import Quiz from "./Quiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Footer from "./footer";
import Test from "./test.js";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
  MDBAccordion,
  MDBAccordionItem,
  MDBIcon,
  MDBCardBody,
  MDBProgress,
  MDBProgressBar,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBFooter,
  MDBDropdownDivider,
} from "mdb-react-ui-kit";
import { BorderColor } from "@mui/icons-material";
import RadialSeparators from "./RadialSeparators";
import { isAlphanumericLocales } from "validator";
toast.configure();
function LessonDetails(props) {
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundsize: "cover",
  };
  const history = useHistory();
  const [CourseContents, setCourseContents] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseGrade, setCourseGrade] = useState("");
  const [currentContentObj, _setCurrentContentObj] = useState(null);
  const [activeContentId, _setActiveContentId] = useState();
  const stateRef = React.useRef(currentContentObj);
  //const lottieRef = React.useRef(showSuccessLottie);
  const activeIdRef = React.useRef(activeContentId);
  const UserId = sessionStorage.getItem("userid");
  const views = sessionStorage.getItem("view");
  let course_ID = sessionStorage.getItem("courseId");
  sessionStorage.setItem("courseTitle", courseTitle);
  const GradeId = sessionStorage.getItem("grade");
  const [Coins, setCoins] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [motivationalMsg, setMotivationalMsg] = useState("");
  const [contentHdrPercentage, setContentHdrPercentage] = useState([]);
  const Time = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: calendar,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const ViewTime = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: Viewtime,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: goldcoin,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const CoinStar = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: goldcoinold,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Enrollnow = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: enrollnow,

      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  // const notify = () =>
  //   toast.success("You have successfully completed the topic", {
  //     toastId: "success1",
  //     autoClose: 4000,
  //   });
  const setCurrentContentObj = (data) => {
    stateRef.current = data;
    _setCurrentContentObj(data);
  };
  //   const setShowSuccessLottie = (isVisible) => {
  //     let lDiv = document.getElementById("succ-lot");

  //     if (isVisible) {
  //       lDiv.style.zIndex = "10";
  //     } else {
  //       lDiv.style.zIndex = "-1";
  //     }

  //     lottieRef.current = isVisible;
  //     _setShowSuccessLottie(isVisible);
  //   };
  const setActiveContentId = (data) => {
    activeIdRef.current = data;
    _setActiveContentId(data);
    sessionStorage.setItem("activeContId", data);
  };
  useEffect(() => {
    let cObj = sessionStorage.getItem("courseObj");
    let obj = JSON.parse(cObj);
    const payload = {
      USER_ID: UserId,
      COURSE_ID: obj.COURSE_ID,
      GRADE: obj.GRADE,
    };
    Axios.post("/user/MotivationalMsg", payload, {
      "content-type": "application/json",
      Accept: "application/json",
    }).then((response) => {
      if (response) {
        //console.log(response.data.result.message)
        setMotivationalMsg(response.data.result.message);
      }
    });
    Axios.get(`/user/Courseprogress/${obj.COURSE_ID}/${UserId}`).then(
      (response) => {
        if (response.data) {
          //console.log(response.data.recordset);CourseDetails
          setContentHdrPercentage(response.data.recordset);
        }
      }
    );
  }, []);

  // async function fetch_content() {
  //   console.log("triggering api");
  //   try {
  //     const response = await Axios.get(`/user/ExamDetails/${UserId}`);
  //     if (response && response.data && response.data.recordset) {
  //       console.log(response.data.recordset, ">>>>>>>>>??????");
  //       return response.data.recordset;
  //     }
  //   } catch (error) {
  //     console.log("API call error:", error);
  //   }
  // }

  useEffect(
    (Coins) => {
      async function fetchDetails(Coins) {
        let cObj = sessionStorage.getItem("courseObj");
        let obj = JSON.parse(cObj);
        // console.log(obj)
        //setCourse(obj)
        setCourseTitle(obj.COURSE_TITLE);
        setCourseGrade(obj.GRADE);
        //console.log(obj.COURSE_ID)
        //setShowSuccessLottie(false); ///CourseDetails
        //document.getElementById("videoPlayer").style.display = "none";
        const dataReq = "";
        const headrReq = "";
        await Axios.get(`/user/CourseDetails/${UserId}/${obj.COURSE_ID}`).then(
          (response) => {
            if (response.data) {
              //console.log(response.data.recordset[0]);
              setCourse(response.data.recordset[0]);
            }
          }
        );

        await Axios.get(`/user/Contents/${obj.COURSE_ID}/${UserId}`).then(
          (response) => {
            if (response.data) {
              // console.log(response.data.recordset, "<<cont247>>>>");
              response.data.recordset.map((content) => {
                if (content.CONTENT_TYPE === 1)
                  content.PERCENTAGE_VIEWED =
                    (content.CURRENT_PROGRESS /
                      content.CONTENT_VIDEO_DURATION) *
                    100;
                else content.PERCENTAGE_VIEWED = 0;
                if (content.CONTENT_PROGRESS_STATUS === 3) {
                  content.CURRENT_PROGRESS = 0;
                  content.PERCENTAGE_VIEWED = 100;
                }
              });
              setCourseContents(response.data.recordset);
            }

            setActiveContentId(0);
            // if(response.data.recordset){
            //   let activeContentObj = response.data.recordset.filter(
            //     (content) => content.CONTENT_PROGRESS_STATUS === 2
            //   )[0];

            //   if (activeContentObj !== null && activeContentObj !== undefined) {
            //     setActiveContentId(activeContentObj.CONTENT_ID);
            //     //console.log(`pending-- ${JSON.stringify(activeContentObj.CONTENT_ID)}`);

            //   } else {
            //     activeContentObj = response.data.recordset.filter(
            //       (content) => content.PARENT_ID === 0
            //     )[0];
            //     //console.log(`parent-- ${activeContentObj.CONTENT_ID}`);
            //     setActiveContentId(activeContentObj.CONTENT_ID);
            //     //  console.log(`parent2-- ${activeContentObj}`);
            //   }
            //   console.log(sessionStorage.getItem('activeContId'));
            // }

            //setCurrentContentObj(activeContentObj);
            //loadLesson(activeContentObj);
          }
        );

        //console.log(`${UserId}, ${obj.GRADE}, ${obj.COURSE_ID}`)
      }
      fetchDetails();
    },
    [Coins]
  );
  //   function loadLesson(contentObj) {

  //     if (contentObj.IS_FREE_PREVIEW === 1) {

  //       setCurrentContentObj(contentObj);

  //     }
  //   }
  const notify = () => {
    toast(
      <div>
        <div
        //style={{ height: "100px", width: "100px" }}
        >
          <Enrollnow />
        </div>
        {"Please Enroll to View This Content"}
      </div>,
      {
        toastId: "success1",
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  const navigateTo = (obj, path) => {
    sessionStorage.setItem("contentObj", JSON.stringify(obj.CONTENT_ID));
    history.push(path);
  };

  return (
    <div style={bgimage} alt="backgroundimage">
      <div>
        <Navbar props={Coins} />
      </div>
      <div>
        <div style={{ margin: "15px" }}>
          <a href={(`/Student`, (props = { Coins }))}>
            <MDBIcon
              size="lg"
              style={{ color: "#a837f2", paddingLeft: "10px" }}
              icon="arrow-circle-left"
            />
          </a>
          <span
            width="30%"
            style={{
              fontSize: "1.3rem",
              paddingLeft: "2rem",
              fontFamily: "Playfair Display",
              fontWeight: "bold",
              color: "#a837f2",
            }}
          >
            {course?.COURSE_TITLE || ""}
            {/* {course !== null ? `${course.COURSE_TITLE}` : course.COURSE_TITLE} */}
            {/* Grade {GradeId} - {courseTitle} */}
          </span>
        </div>
      </div>
      <div>
        <MDBRow className="px-5">
          <MDBRow style={{ paddingBottom: "50px" }}>
            <MDBCol
              // className="px-5"
              lg="7"
            >
              <Accordion
                id="lessonView"
                key="lessonView"
                defaultActiveKey={0}
                style={{ fontFamily: "Playfair Display" }}
              >
                {CourseContents &&
                  CourseContents.filter(
                    (content) => content.PARENT_ID === 0
                  ).map((content_det, mainIndex) => (
                    <Accordion.Item
                      style={{ padding: "0rem" }}
                      key={"ai-" + mainIndex + "-" + content_det.CONTENT_ID}
                      eventKey={mainIndex}
                    >
                      <Accordion.Header>
                        <MDBCol md="8">{content_det.CONTENT_TITLE}</MDBCol>

                        <MDBCol md="4">
                          <MDBBadge
                            style={{
                              // paddingLeft: '745px',
                              backgroundColor: "#4da6ff",
                              paddingRight: "4px",
                            }}
                            className="text-dark float-end"
                            color="light"
                          >
                            {contentHdrPercentage &&
                              Math.round(
                                (contentHdrPercentage.filter(
                                  (hdrP) =>
                                    hdrP.CONTENT_ID === content_det.CONTENT_ID
                                )[0].COMPLETED_LESSONS /
                                  contentHdrPercentage.filter(
                                    (hdrP) =>
                                      hdrP.CONTENT_ID === content_det.CONTENT_ID
                                  )[0].LESSONS) *
                                  100
                              )}
                            %
                            <MDBIcon
                              fas
                              icon="eye"
                              size="lg"
                              className="float-end"
                              style={{
                                paddingLeft: "5px",
                                paddingRight: "2px",
                              }}
                            />
                          </MDBBadge>
                        </MDBCol>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul
                          className="list-group list-group-flush "
                          style={{ padding: "0px", margin: "0px" }}
                        >
                          <>
                            <MDBRow
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {CourseContents.filter(
                                (cContent) =>
                                  cContent.PARENT_ID === content_det.CONTENT_ID
                              ).map((obj, index, arr) => (
                                <>
                                  {index % 6 === 0 ||
                                  index % 6 === 1 ||
                                  index % 6 === 5 ||
                                  index % 6 === 6 ? (
                                    <MDBCol
                                      size="6"
                                      className="wrapper"
                                      key={"col" + index}
                                    >
                                      {" "}
                                      <>
                                        {arr[index].PREDECESSORS_COMPLETED ===
                                        0 ? (
                                          <div
                                            style={{ width: 90, height: 90 }}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={
                                                arr[index].PERCENTAGE_VIEWED
                                              }
                                              text={index + 1}
                                              strokeWidth={10}
                                              background
                                              styles={buildStyles({
                                                strokeLinecap: "butt",
                                                backgroundColor: "#4da6ff",
                                                textColor: "#fff",
                                                pathColor: "#a837f2",
                                                textSize: "35px",
                                              })} //#009641
                                            >
                                              <MDBIcon
                                                fas
                                                icon="lock"
                                                color="white"
                                                className="lessonIcon"
                                                size="3x"
                                              />
                                            </CircularProgressbarWithChildren>
                                          </div>
                                        ) : (
                                          <a
                                            href="#!"
                                            onClick={() => {
                                              if (
                                                arr[index].CONTENT_TYPE === 1 ||
                                                arr[index].CONTENT_TYPE === 2 ||
                                                arr[index].CONTENT_TYPE === 4 ||
                                                arr[index].CONTENT_TYPE === 7
                                              ) {
                                                navigateTo(obj, "/Content");
                                              } else if (
                                                arr[index].CONTENT_TYPE === 5
                                              ) {
                                                window.open(
                                                  arr[index].CONTENT_VIDEO
                                                );
                                              } else if (
                                                arr[index].CONTENT_TYPE === 6
                                              ) {
                                                window.open(
                                                  arr[index].CONTENT_VIDEO
                                                );
                                              }
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 90,
                                                height: 90,
                                              }}
                                            >
                                              <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                text={index + 1}
                                                strokeWidth={10}
                                                background
                                                styles={buildStyles({
                                                  strokeLinecap: "butt",
                                                  backgroundColor: "#a837f2",
                                                  textColor: "#fff",
                                                  pathColor: "#4da6ff",
                                                  textSize: "35px",
                                                })}
                                              ></CircularProgressbarWithChildren>
                                            </div>
                                          </a>
                                        )}
                                      </>
                                      <MDBCardBody
                                        style={{
                                          paddingBottom: "30px",
                                          fontSize: "18px",
                                          fontFamily: "Playfair Display",
                                          fontWeight: "true",
                                        }}
                                      >
                                        {arr[index].CONTENT_TYPE === 3
                                          ? `Quiz On ${arr[index].CONTENT_TITLE}`
                                          : arr[index].CONTENT_TITLE}
                                      </MDBCardBody>
                                    </MDBCol>
                                  ) : (
                                    <MDBCol size="12" className="wrapper">
                                      <>
                                        {arr[index].PREDECESSORS_COMPLETED ===
                                        0 ? (
                                          <div
                                            style={{ width: 90, height: 90 }}
                                          >
                                            <CircularProgressbarWithChildren
                                              value={
                                                arr[index].PERCENTAGE_VIEWED
                                              }
                                              text={index + 1}
                                              strokeWidth={10}
                                              background
                                              styles={buildStyles({
                                                strokeLinecap: "butt",
                                                backgroundColor: "#4da6ff",
                                                textColor: "#fff",
                                                pathColor: "#a837f2",
                                                textSize: "35px",
                                              })}
                                            >
                                              <MDBIcon
                                                fas
                                                icon="lock"
                                                color="white"
                                                className="lessonIcon"
                                                size="3x"
                                              />
                                            </CircularProgressbarWithChildren>
                                          </div>
                                        ) : (
                                          <a
                                            href="#!"
                                            onClick={() =>
                                              navigateTo(obj, "/Content")
                                            }
                                          >
                                            <div
                                              style={{
                                                width: 90,
                                                height: 90,
                                              }}
                                            >
                                              <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                text={index + 1}
                                                strokeWidth={10}
                                                background
                                                styles={buildStyles({
                                                  strokeLinecap: "butt",
                                                  backgroundColor: "#a837f2",
                                                  textColor: "#fff",
                                                  pathColor: "#4da6ff",
                                                  textSize: "35px",
                                                })}
                                              ></CircularProgressbarWithChildren>
                                            </div>
                                          </a>
                                        )}
                                      </>
                                      <MDBCardBody
                                        style={{
                                          paddingBottom: "30px",
                                          fontSize: "18px",
                                          fontFamily: "Playfair Display",
                                          fontWeight: "true",
                                        }}
                                      >
                                        {/* {arr[index].CONTENT_TITLE} */}
                                        {arr[index].CONTENT_TYPE === 3
                                          ? `Quiz On ${arr[index].CONTENT_TITLE}`
                                          : arr[index].CONTENT_TITLE}
                                      </MDBCardBody>
                                    </MDBCol>
                                  )}
                                </>
                              ))}
                            </MDBRow>
                          </>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </MDBCol>
            <MDBCol sm="5" style={{ paddingLeft: "50px" }}>
              <MDBRow style={{ paddingBottom: "30px" }}>
                <MDBCard
                  border="gray"
                  style={{
                    width: "100%",
                    // backgroundImage: `url(${Imagecard})`,
                    // backgroundSize: 'cover',
                    //backgroundColor: '#009FE3',
                    backgroundposition: "center",
                    backgroundRepeat: "no-repeat",
                    fontFamily: "Playfair Display",
                    fontSize: "1.5rem",
                  }}
                  className="gradient-orange"
                >
                  <MDBCardBody style={{ color: "white" }}>
                    <div class="wrap">
                      <div class="td" style={{ width: "25%" }}>
                        <span style={{ height: "100%", width: "100%" }}>
                          <CoinStar />
                        </span>
                      </div>
                      <div
                        class="td"
                        style={{
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          width: "75%",
                        }}
                      >
                        <MDBCardText>{motivationalMsg}</MDBCardText>
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
              <MDBRow style={{ paddingBottom: "30px" }}>
                <MDBCard
                  border="gray"
                  style={{
                    width: "100%",
                    // backgroundImage: `url(${Imagecards})`,
                    // backgroundSize: 'cover',
                    //backgroundColor: '#A21A5B',
                    backgroundposition: "center",
                    backgroundRepeat: "no-repeat",
                    fontFamily: "Playfair Display",
                    fontSize: "1.5rem",
                  }}
                  className="gradient-green"
                >
                  <MDBCardBody
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div class="wrap">
                      <div
                        class="td"
                        style={{
                          width: "25%",
                          justifyContent: "center",
                          paddingLeft: "25px",
                          paddingTop: "15px",
                        }}
                      >
                        <Progress
                          variant="whitetext"
                          type="circle"
                          width={70}
                          percent={Math.round(
                            (course.COMPLETED / course.TOTAL) * 100
                          )}
                          status="success"
                          theme={{
                            success: {
                              symbol: (
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  {Math.round(
                                    (course.COMPLETED / course.TOTAL) * 100
                                  ) + String("%")}{" "}
                                </span>
                              ),

                              color: "#009641",
                              trailColor: "white",
                            },
                          }}
                        />
                      </div>
                      <div
                        class="td"
                        style={{
                          paddingLeft: "15px",
                          paddingTop: "15px",
                          width: "75%",
                        }}
                      >
                        <p>
                          {Math.round((course.COMPLETED / course.TOTAL) * 100) >
                          50 ? (
                            <div>
                              {Math.round(
                                (course.COMPLETED / course.TOTAL) * 100
                              )}
                              % of the Course is Completed!! <br />
                              Great Going!!
                            </div>
                          ) : (
                            <div>
                              {Math.round(
                                (course.COMPLETED / course.TOTAL) * 100
                              )}
                              % of the Course is Completed!! <br /> You Can Do
                              Much More!!{" "}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
              <MDBRow style={{ paddingBottom: "30px" }}>
                <MDBCard
                  border="gray"
                  style={{
                    width: "100%",
                    //backgroundColor: '#009641',
                    // backgroundImage: `url(${Imagecard})`,
                    // backgroundSize: 'cover',
                    backgroundposition: "center",
                    backgroundRepeat: "no-repeat",
                    fontFamily: "Playfair Display",
                    fontSize: "1.5rem",
                  }}
                  className="gradient-blue"
                >
                  <MDBCardBody
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div class="wrap">
                      <div class="td" style={{ width: "25%" }}>
                        <span style={{ height: "100%", width: "100%" }}>
                          <Coin />
                        </span>
                      </div>
                      <div
                        class="td"
                        style={{
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          width: "75%",
                        }}
                      >
                        {course.TOTAL_AVAILABLE_POINTS - course.TOTAL_SCORE >
                        0 ? (
                          <div>
                            You can Earn{" "}
                            {course.TOTAL_AVAILABLE_POINTS - course.TOTAL_SCORE}{" "}
                            Points More, By Completing this Course.
                          </div>
                        ) : (
                          <div>
                            Congratulations on Successfully Completing the
                            Course!!! Do Check Out our Suggested Courses and
                            Earn MORE!
                          </div>
                        )}
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
              <MDBRow style={{ paddingBottom: "30px" }}>
                <MDBCard
                  border="gray"
                  style={{
                    width: "100%",
                    // backgroundImage: `url(${Imagecards})`,
                    // backgroundSize: 'cover',
                    //backgroundColor: '#F39200',
                    backgroundposition: "center",
                    backgroundRepeat: "no-repeat",
                    fontFamily: "Playfair Display",
                    fontSize: "1.5rem",
                  }}
                  className="gradient-mustard"
                >
                  <MDBCardBody
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div class="wrap">
                      <div class="td" style={{ width: "25%" }}>
                        <span style={{ height: "100%", width: "100%" }}>
                          <Time />
                        </span>
                      </div>
                      <div
                        class="td"
                        style={{
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          width: "75%",
                        }}
                      >
                        {course.LAST_ACTIVITY_DAYS > 0 ? (
                          <div>
                            We missed you since {course.LAST_ACTIVITY_DAYS}{" "}
                            {course.LAST_ACTIVITY_DAYS === 1 ? (
                              <span>day!</span>
                            ) : (
                              <span>days!</span>
                            )}{" "}
                          </div>
                        ) : (
                          <div>Appreciate your Prompt Learning</div>
                        )}
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
              <MDBRow style={{ paddingBottom: "30px" }}>
                <MDBCard
                  border="gray"
                  style={{
                    width: "100%",
                    // backgroundImage: `url(${Imagecards})`,
                    // backgroundSize: 'cover',
                    //backgroundColor: '#4da6ff',
                    backgroundposition: "center",
                    backgroundRepeat: "no-repeat",
                    fontFamily: "Playfair Display",
                    fontSize: "1.5rem",
                  }}
                  className="gradient-purple"
                >
                  <MDBCardBody
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div class="wrap">
                      <div class="td" style={{ width: "25%" }}>
                        <span style={{ height: "90%", width: "90%" }}>
                          <ViewTime />
                        </span>
                      </div>
                      <div
                        class="td"
                        style={{
                          paddingLeft: "5px",
                          paddingTop: "5px",
                          width: "75%",
                        }}
                      >
                        {" "}
                        {Math.round(course.TOTAL_SECONDS / 60)} Minutes Viewed
                      </div>
                    </div>

                    {/* Active since 100 days!! */}
                    <div
                      style={{ paddingLeft: "35px", paddingTop: "5px" }}
                    ></div>
                  </MDBCardBody>
                </MDBCard>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBRow>
      </div>
      {/* <MDBFooter className="absolute">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position:'absolute',
              left:'0',
              bottom:'0',
              right:'0'
            }}
          >
            <MDBCol
              size="10"
              className="text-center p-3"
              style={{
                fontSize: '10px',
                // backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
            >
              © 2022 Copyright:
              <a className="text-black" href="http://lmsdev.padhobadho.com/">
                PadhoBadho
              </a>
            </MDBCol>
            <MDBCol
              size="2"
              //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
              <section
                className="float-end "
                style={{
                  paddingTop: '5px',
                  paddingRight: '10px',
                  fontSize: '10px',
                }}
              >
                <a
                  className="  btn-floating text-black"
                  href="#!"
                  role="button"
                >
                  <MDBIcon fab icon="facebook-f" />
                </a>
                <a
                  className=" btn-floating text-black "
                  href="#!"
                  role="button"
                  size="10px"
                >
                  <MDBIcon fab icon="instagram" />
                </a>

                <a className=" btn-floating text-black" href="#!" role="button">
                  <MDBIcon fab icon="linkedin-in" />
                </a>
              </section>
            </MDBCol>
          </div>
          <MDBDropdownDivider></MDBDropdownDivider>
        </MDBFooter> */}
    </div>
  );
}
export default LessonDetails;
