import React, { useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Axios from "axios";
import validator from "validator";
import { useState } from "react";
import Image from "./assets/home-dashboard.png";
// import Image from './assets/hunar-ki-gali-banner.png'
import drop from "./assets/Sign.png";
import group from "./assets/Group5.png";
import { useLottie } from "lottie-react";
import Lottie from "../src/Student/Lotties/bg.json";
import Lottie1 from "../src/Student/Lotties/bg1.json";
import Lottie2 from "../src/Student/Lotties/bg2.json";
import logo from "./assets/lst-logo.jpeg";
import login from "./assets/login-image.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBNavbarBrand,
  MDBIcon,
  MDBFooter,
  MDBDropdownDivider,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { height } from "@mui/system";
const Signin = (props) => {
  const formval = { fontWeight: "bold", color: "red", fontSize: "12px" };
  const [mobile, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [name, setname] = useState();
  // const [User, setUser] = useState('Admin');
  const [lastname, setLastName] = useState();
  const [score, setscore] = useState();
  const [grade, setgrade] = useState();
  const [userid, setuserid] = useState();
  const [ErrMobile, setErrMobile] = useState("");
  const [ErrPassword, setErrPassword] = useState("");
  const [dynamicMessage, setDynamicMessage] = useState("");
  const [schoolName, setSchoolName] = useState("");
  sessionStorage.setItem("usermobile", mobile);
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroungRepeat: "no-repeat",
    height: "100vh",
    opacity: "0.5",
  };
  const image = {
    backgroundImage: `url(${drop})`,
    backgroungRepeat: "no-repeat",
    // backgroundSize: 'auto',
    height: "100vh",
  };
  const ani = {
    width: "200px",
    height: "92px",
    backgroundImage: `url(${group})`,
    animationName: " example",
    position: " relative",
    animationDuration: "4s",
    zIndex: "99",
    animationIterationCount: "infinite",
  };
  const ani1 = {
    width: "500px",
    height: "600px",
    backgroundImage: `url(${drop})`,
    animationName: " example1",
    position: " relative",
    animationDuration: "4s",
    zIndex: "999",
  };

  useEffect(() => {
    // if (!window.matchMedia("(min-width: 1025px)").matches) {
    //   window.location.replace("/mobilepage");
    // }
    GetDynamicContent();
  }, []);

  const Animation = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: Lottie,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Animations = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: Lottie1,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  const Animationss = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: Lottie2,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid slice",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  function GetDynamicContent() {
    var arr = [
      "We believe education and skills together can define your success",
      "Our ambition, your success",
      "Our motivation is a bright future for you. Yes you!",
      "Our ambition is that Education/Studies plus Skills combination reaches every doorstep. Each village, district and every corner of our country. We aspire that you build a capacity to live confidently in this 21st Century.",
      "My SkillForge is committed to help ignite the flame of larger purpose in your life. We believe that co-existence of Education/Studies plus Skills will define a new charter of success by building a strong foundation.",
      "Did You Know that despite 15 years of rigorous education (12 years in schools and 3 years in college) nearly 54.1% of our youth is not job-ready which indicates a huge skills gap in education which needs to be addressed immediate.",
    ];
    //  return arr[Math.floor(Math.random() * arr.length)];
    setDynamicMessage(arr[Math.floor(Math.random() * arr.length)]);
  }
  const sendDetailsToServer = (e) => {
    e.preventDefault();
    // props.history.replace("/Student");
    if (mobile !== null && Password !== null) {
      const payload = {
        MOBILE_NUMBER: mobile,
        PASSWORD: Password,
      };
      // Axios.post("http://localhost:9000/signin", payload, {
      Axios.post("/user/signin", payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          if (response.data.userdata) {
            setuserid(response.data.userdata.USER_ID);
            setname(response.data.userdata.FIRST_NAME);
            setLastName(response.data.userdata.LAST_NAME);
            setSchoolName(response.data.userdata.SCHOOL_NAME);
            setgrade(response.data.userdata.GRADE);
            setscore(response.data.userdata.COINS);
            sessionStorage.setItem("userid", response.data.userdata.USER_ID);
            sessionStorage.setItem("score", response.data.userdata.COINS);
            sessionStorage.setItem("grade", response.data.userdata.GRADE);
            sessionStorage.setItem("email", response.data.userdata.EMAIL);
            sessionStorage.setItem("testUrl", response.data.userdata.TEST_URL);
            sessionStorage.setItem(
              "mobile",
              response.data.userdata.MOBILE_NUMBER
            );
            sessionStorage.setItem(
              "username",
              response.data.userdata.FIRST_NAME
            );
            // sessionStorage.setItem(
            //   "lastName",
            //   response.data.userdata.LAST_NAME
            // );
            sessionStorage.setItem(
              "schoolName",
              response.data.userdata.SCHOOL_NAME
            );
          }
          if (response.status === 200) {
            if (
              sessionStorage.getItem("username") !== "null" &&
              sessionStorage.getItem("username") !== undefined
            ) {
              props.history.replace("/Student");
            } else {
              toast.warning(<div>{"Please Complete your profile "}</div>, {
                toastId: "success1",
                autoClose: 2000,
                position: toast.POSITION.TOP_RIGHT,
              });
              props.history.replace("/Profile");
            }

            // navigate('/Student', { replace: true });
          } else if (response.status === 201) {
            toast.warning(
              <div>{"User does not exist. Please Register now "}</div>,
              {
                toastId: "noUser",
                autoClose: 2000,
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else if (response.status === 402) {
            toast.warning(<div>{"Please Enter Vaild Details "}</div>, {
              toastId: "invalidDet",
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.log("err in login", error);
          if (error.message)
            toast.warning(<div>{"Please Enter Vaild Details "}</div>, {
              toastId: "Erro",
              // autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
            });
        });
    } else {
      console.log("Invalid Data");
    }
  };
  return (
    <>
      <div>
        <div>
          <MDBRow size="12">
            <MDBCol className="float-start">
              <a style={{ marginRight: "0px" }} href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "10px",
                    paddingRight: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <img src={logo} style={{ height: "90px" }} alt="" />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="2"></MDBCol>
          </MDBRow>
        </div>
        <div style={bgimage} alt="backgroundimage">
          {/* <MDBFooter className="fixed">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <MDBCol
              size="10"
              className="text-center p-3"
              style={{
                fontSize: '10px',
                // backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
            >
              © 2022 Copyright:
              <a className="text-black" href="http://lmsdev.padhobadho.com/">
                My SkillForge
              </a>
            </MDBCol>
            <MDBCol
              size="2"
              //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
              <section
                className="float-end "
                style={{
                  paddingTop: '5px',
                  paddingRight: '10px',
                  fontSize: '10px',
                }}
              >
                <a
                  className="  btn-floating text-black"
                  href="#!"
                  role="button"
                >
                  <MDBIcon fab icon="facebook-f" />
                </a>

               
                <a
                  className=" btn-floating text-black "
                  href="#!"
                  role="button"
                  size="10px"
                >
                  <MDBIcon fab icon="instagram" />
                </a>

                <a className=" btn-floating text-black" href="#!" role="button">
                  <MDBIcon fab icon="linkedin-in" />
                </a>
              </section>
            </MDBCol>
          </div>
          <MDBDropdownDivider></MDBDropdownDivider>
        </MDBFooter>
         */}
        </div>
        <MDBModal staticBackdrop show={true} tabIndex="-1">
          <MDBModalDialog size="md-down" centered>
            <MDBModalContent>
              <MDBModalBody>
                <MDBCol>
                  <div
                    style={{
                      paddingBottom: "5px",
                      display: "flex",
                      justifyContent: "center",
                      color: "#9337F1",
                      fontWeight: "bold",
                      fontSize: "35px",
                      fontFamily: "Helvetica Oblique",
                    }}
                  >
                    <img src={login} style={{ height: "7vw" }} alt="img" />
                  </div>
                  {/* <div
                    style={{
                      fontFamily: "PlayfairDisplay",
                      fontWeight: "bold",
                      fontSize: "18px",
                      padding: "15px",
                      textAlign: "justify",
                    }}
                  >
                    {dynamicMessage}
                  </div> */}
                  <form>
                    <MDBInput
                      // label="Mobile Number"
                      autoFocus
                      maxLength="10"
                      placeholder="Mobile Number"
                      class="borderr"
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "50px",
                        paddingBottom: "18px",
                        paddingLeft: "7px",
                      }}
                      tabIndex="1"
                      // size="lg"
                      required
                      onChange={(e) => {
                        if (e.target.value.match("^\\d{10}$") != null) {
                          setEmail(e.target.value);
                          setErrMobile("");
                        } else {
                          setErrMobile("Please Enter a valid Mobile Number");
                        }
                      }}
                    />
                    <MDBTypography style={formval}>{ErrMobile}</MDBTypography>
                    {/* <MDBInputGroup
                    style={{ backgroundColor: "#ffffff" }}
                    type="password"
                    variant="outlined"
                    border-radius="5px"
                    textAfter={
                      <MDBIcon
                        fas
                        icon="eye"
                        size="lg"
                        variant="outlined"
                        style={{
                          color: "#B2BEB5",
                          // backgroundColor: "#ffffff",
                        }}
                      />
                    }
                  >
                    <MDBInput
                      label="Password"
                      placeholder="Enter password"
                      type="password"
                      variant="outlined"
                      size="sm"
                      style={{ backgroundColor: "#FFFFFF" }}
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (
                          validator.isStrongPassword(e.target.value, {
                            minLength: 8,
                            minLowercase: 1,
                            minUppercase: 1,
                            minNumbers: 1,
                            minSymbols: 1,
                          })
                        ) {
                          setErrPassword("");
                        } else {
                          setErrPassword("Please enter your password");
                        }
                      }}
                    />
                  </MDBInputGroup> */}
                    <MDBInput
                      // label="Password"
                      placeholder="Password"
                      maxLength="4"
                      type="password"
                      // border="#FE3D0B"
                      class="borderr"
                      // size="lg"
                      style={{
                        // backgroundColor: '#283EF4',
                        width: "100%",
                        height: "50px",
                        paddingLeft: "7px",
                      }}
                      required
                      onChange={(e) => {
                        if (e.target.value.match("^\\d{4}$") != null) {
                          setPassword(e.target.value);
                          setErrPassword("");
                        } else {
                          setErrPassword("Please enter 4 digit password");
                        }
                      }}
                    />

                    <MDBTypography style={formval}>{ErrPassword}</MDBTypography>

                    <MDBRow>
                      <MDBCol size="6"></MDBCol>
                      <MDBCol size="6">
                        <MDBTypography
                          className="float-end"
                          style={{
                            fontSize: "15px",

                            // marginLeft: '35%',
                          }}
                        >
                          {/* <a href="/forgotpassword">Forgot Pin?</a> */}
                        </MDBTypography>
                      </MDBCol>
                    </MDBRow>
                    <MDBBtn
                      type="submit"
                      size="lg"
                      block
                      style={{
                        background: "linear-gradient(to left, #333FE7,#CA1DDD)",
                        borderRadius: "10px",
                        letterSpacing: "1.5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => sendDetailsToServer(e)}
                    >
                      LOGIN
                    </MDBBtn>
                    {/* <MDBContainer className="pt-3 pb-2">
                      <div
                        color="#FE3D0B"
                        className="text-center"
                        style={{ fontSize: '20px' }}
                      >
                        {' '}
                        New to My SkillForge ? 
                        <a href="/SignUp"  color="#FE3D0B">
                          Register Now
                        </a>
                      </div>
                    </MDBContainer> */}
                  </form>
                </MDBCol>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
};

export default Signin;
